import store from "../../state/store";
/**
 * Open dialog arguments
 * @param {Object} data OpenDialogArguments
 * @param {String} data.Title Dialog title
 * @param {String} data.Template Dialog template
 * @param {Object} data.Data Dialog data
 */
export default function (data) {
    store.commit("dialog/setTitle", data.Title);
    store.commit("dialog/setTemplate", data.Template);
    store.commit("dialog/setData", data.Data);
    store.commit("dialog/showDialog", true);
}
